import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import FAQ from './FAQ'


export default function MiscShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    faq: FAQ
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
