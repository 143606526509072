// Components
import Form from '~/components/elements/Form'
import Content from '~/components/elements/Content'
import { Tooltip } from 'react-tooltip'

// Types
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import { FormDuufField, FormDuufSubmit, useFormDuuf } from '@ubo/form-duuf'
import Arrow from '~/components/elements/svg/Arrow'
import { Fragment, useEffect, useState } from 'react'
import clsx from 'clsx'
import useClientMediaQuery from '~/hooks/useClientMediaQuery'
import { useLosseLanguage } from '@ubo/losse-sjedel'

export default function FormGrowthTool({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  return (
    <section
      className="min-h-screen bg-ubo-whiscash pt-10 xl:pt-0"
      data-component="FormGrowthTool"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
    >
      <style>
        {`
          #header ul li {
              display: none;
          }

          .bg-white.header-sticky-mobile {
            display: none;
          }
        `}
      </style>
      <div className="container flex flex-col items-center py-7 lg:py-28">
        <Form
          generate={false}
          data={fields.form}
          renderLoading={() => (
            <svg className="h-10 w-10 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          )}
          defaultFields={{
            input_46: typeof window !== 'undefined' ? crypto.randomUUID() : ''
          }}
          renderStatus={({ content }) => <Content className="content-lg bg-ubo-whiscash p-12 text-white">{content}</Content>}
          className="form-growth-tool"
          privacyUrl="/privacyverklaring"
          privacyUrlPlacement="privacybeleid"
          scrollToConfirm={false}
          onSubmitDone={(values) => {
            const id = values['input_46']
            window.localStorage.setItem('growth-tool', id)
            window.location.href = `/tools/the-grow-business-grader/${id}`
          }}
        >
          <FormInner />
        </Form>
      </div>
    </section>
  )
}

const pageTitlesNL = [
  {
    title: 'Branding/Exposure',
    info: 'Branding en exposure zijn onlosmakelijk met elkaar verbonden als het gaat om het vergroten van de zichtbaarheid van jouw merk. Een sterke branding vormt de basis voor een succesvolle strategie. Het is dan ook van groot belang om te weten hoe jouw doelgroep jouw merk ziet om daar actief op in te spelen.'
  },
  {
    title: 'Triggers',
    info: 'De triggers van je doelgroep vormen het begin van de buyer journey en daarom is het essentieel om te begrijpen welke factoren hen in beweging zetten. Het kennen van de triggers stelt je in staat om hierop in te spelen en je doelgroep daadwerkelijk te bereiken.'
  },
  {
    title: 'Verkenning',
    info: 'Verkenning is een cruciale fase in de buyer journey, waarbij de doelgroep op zoek gaat naar informatie en opties om aan hun behoeften te voldoen. Het is daarom van groot belang om aanwezig te zijn op de plaatsen waar jouw potentiële klanten zich bevinden, om zo de kans te vergroten dat jouw doelgroep bij jou uitkomt tijdens deze fase.'
  },
  {
    title: 'Verdieping',
    info: 'Tijdens de verdieping zal de potentiële klant de gevonden oplossingen en jou als organisatie grondig evalueren. Tijdens deze fase wordt er besloten of een bepaalde oplossing echt de juiste is en of er een goede match is met jouw bedrijf. Het is van belang dat je antwoord geeft op de vragen die de klant heeft en hem reden geeft om in contact te komen of zaken te doen.'
  },
  {
    title: 'Experience',
    info: 'Experience, oftewel de klantervaring, is het laatste onderdeel van de buyer journey. Hierbij is het van groot belang om te doen wat je belooft en zo de verwachtingen van de klant te overtreffen.'
  },
  {
    title: 'Jouw informatie'
  }
]

const pageTitlesEN = [
  {
    title: 'Branding/Exposure',
    info: 'Branding and exposure are inextricably linked when it comes to increasing the visibility of your brand. A strong branding forms the basis for a successful strategy. It is therefore of great importance to know how your target group sees your brand in order to actively respond to it.'
  },
  {
    title: 'Triggers',
    info: 'The triggers of your target group form the beginning of the buyer journey and therefore it is essential to understand which factors set them in motion. Knowing the triggers enables you to respond to them and actually reach your target group.'
  },
  {
    title: 'Exploration',
    info: 'Exploration is a crucial phase in the buyer journey, in which the target group is looking for information and options to meet their needs. It is therefore of great importance to be present in the places where your potential customers are, in order to increase the chance that your target group will end up with you during this phase.'
  },
  {
    title: 'Deepening',
    info: 'During the deepening phase, the potential customer will thoroughly evaluate the solutions found and you as an organization. During this phase, it is decided whether a certain solution is really the right one and whether there is a good match with your company. It is important that you answer the questions the customer has and give him a reason to get in touch or do business.'
  },
  {
    title: 'Experience',
    info: "Experience, or the customer experience, is the last part of the buyer journey. It is of great importance to do what you promise and thus exceed the customer's expectations."
  },
  {
    title: 'Your information'
  }
]

const FormInner = () => {
  const [, locale] = useLosseLanguage()
  const duuf = useFormDuuf()

  const [page, setPage] = useState(0)
  const pages = [
    [1, 4, 5, 6, 7],
    [9, 12, 13, 14, 15],
    [17, 19, 20, 21, 22],
    [24, 25, 26, 27, 28],
    [31, 30, 32, 33, 34],
    [43, 38, 39, 44, 40, 41, 45]
  ]

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0
    })
  }, [page])

  const isMobile = useClientMediaQuery('(max-width: 991px)')

  const pageTitles = locale === 'nl_NL' ? pageTitlesNL : pageTitlesEN

  return (
    <div className="mt-4 flex flex-col gap-y-6 sm:gap-y-8">
      {/* Progress */}
      <ul className="mx-auto flex items-center justify-center gap-x-2">
        {pages.map((_, i) => (
          <Fragment key={i}>
            <li
              className={clsx(
                'flex h-4 w-4 items-center justify-center rounded-full border border-white sm:h-6 sm:w-6',
                i <= page ? 'bg-white' : 'bg-transparent'
              )}
            >
              {i <= page && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="h-4 w-4 text-ubo-whiscash"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17 4 12"></path>
                </svg>
              )}
            </li>
            {i !== pages.length - 1 && <div className="h-1 w-6 rounded bg-white sm:w-8"></div>}
          </Fragment>
        ))}
      </ul>

      <div>
        <h2 className="mb-3 flex items-center justify-center gap-x-2 text-center text-xl font-bold text-white sm:mb-4 sm:text-2xl">
          {pageTitles[page].title}

          {pageTitles[page].info && (
            <svg
              data-tooltip-id="my-tooltip"
              data-tooltip-content={pageTitles[page].info}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="h-6 w-6"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </h2>
        <Tooltip
          id="my-tooltip"
          place={isMobile ? 'bottom' : 'right'}
          className="!z-30 max-w-xs !rounded-lg !bg-white !text-sm !text-ubo-taillow !opacity-100 !shadow sm:max-w-lg lg:!text-lg"
        />
        {page !== pages.length - 1 && (
          <p className="mb-2 text-center text-sm text-white sm:mb-0 sm:text-base">
            {locale === 'nl_NL'
              ? 'Ben je het eens of oneens met de volgende stellingen?'
              : 'Do you agree or disagree with the following statements?'}
          </p>
        )}
      </div>

      <div className={clsx(page === pages.length - 1 ? 'last-step' : 'flex flex-col gap-y-8')}>
        {pages[page].map((id) => (
          <FormDuufField key={id} id={id} />
        ))}
      </div>

      <div className="flex w-full justify-between">
        {page !== 0 && (
          <button
            className="btn btn--light--filled mr-auto gap-x-4"
            onClick={() => {
              setPage(page - 1)
            }}
            type="button"
          >
            <Arrow type="lightBlue" className="-mr-1 rotate-180 fill-ubo-whiscash text-ubo-whiscash" />
            {locale === 'nl_NL' ? 'Vorige' : 'Previous'}
          </button>
        )}

        {page !== pages.length - 1 ? (
          <button
            className="btn btn--light--filled ml-auto gap-x-4"
            onClick={() => {
              const inputs = pages[page]
              const isEmpty = inputs.find((input) => {
                if (!duuf.formik.values[`input_${input}`]) {
                  return true
                }
                return false
              })
              if (isEmpty) {
                alert('Vul alle velden in')
                return
              }
              setPage(page + 1)
            }}
            type="button"
          >
            {locale === 'nl_NL' ? 'Volgende' : 'Next'}
            <Arrow type="lightBlue" className="-mr-1 fill-ubo-whiscash text-ubo-whiscash" />
          </button>
        ) : (
          <FormDuufSubmit />
          // <button className="btn btn--light--filled ml-auto gap-x-4" type="submit">
          //   Bekijk mijn resultaten
          //   <Arrow type="lightBlue" className="-mr-1 fill-ubo-whiscash text-ubo-whiscash" />
          // </button>
        )}
      </div>
    </div>
  )
}
